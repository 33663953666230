import './style.css'
import './webContent.css';
import streamkarlogo from '../../img/streamkarlogo.png';
import { Row, Col, Typography, Card, Carousel } from "antd";
import { useRef } from 'react'
import facebook from '../../img/facebook.png';
import insta from '../../img/Insta.png';
import doginitiative from '../../img/doginitiative.jpg';
import foodinitiative from '../../img/foodinitiative.jpg';
import maskinitiative from '../../img/maskinitiative.jpg';
import lArrow from '../../img/left arrow.png';
import rArrow from '../../img/right arrow.png';
import i0 from '../../img/Images/Dog/1.png';
import i1 from '../../img/Images/Dog/10.png';
import i2 from '../../img/Images/Dog/11.png';
import i3 from '../../img/Images/Dog/12.png';
import i4 from '../../img/Images/Dog/8.png';
import i5 from '../../img/Images/Dog/9.png';
import i6 from '../../img/Images/Food/1.png';
import i7 from '../../img/Images/Food/10.png';
import i8 from '../../img/Images/Food/11.png';
import i9 from '../../img/Images/Food/12.png';
import i10 from '../../img/Images/Food/13.png';
import i11 from '../../img/Images/Food/14.png';
import i12 from '../../img/Images/Food/15.png';
import i13 from '../../img/Images/Food/5.png';
import i14 from '../../img/Images/Food/6.png';
import i15 from '../../img/Images/Food/7.png';
import i16 from '../../img/Images/Food/8.png';
import i17 from '../../img/Images/Food/9.png';
import i18 from '../../img/Images/Mask GiveAway/1.png';
import i19 from '../../img/Images/Mask GiveAway/2.png';
import i20 from '../../img/Images/Mask GiveAway/3.png'
import i21 from '../../img/Images/Mask GiveAway/4.png';
import i22 from '../../img/Images/Mask GiveAway/5.png';
import i23 from '../../img/Images/Mask GiveAway/6.png';
import i24 from '../../img/Images/Mask GiveAway/7.png';
import v0 from '../../img/Videos/COVID-19 Pandemic _ Providing essential services during lockdown.mp4';
import v1 from '../../img/Videos/Tough time wont last _ Lets fight this CoVid-19 Situation together _ Stay Safe Stay Home.mp4';
import initiativebanner from '../../img/initiativebanner.png';
import { LeftCircleFilled, MailOutlined, RightCircleFilled, DownOutlined, HomeOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;
const videos = [v0, v1]
const gallery = [i0, i1, i2, i3, i4, i5, i6, i7,
    i8, i9, i10, i11, i12, i13, i14, i15, i16, i17, i18, i19, i20, i21, i22, i23, i24]

const CharityWebsite = () => {
    const videoCarousel = useRef(null);
    const galleryCarousel = useRef(null);

    return (<>
        <div className="backgroundimage backgroundimage2 ">
            <div className='content'>
                <Row justify={'center'} align={'middle'} >
                    <Col xs={4} md={2} xl={2} xxl={2} className='menu-item'>
                        <img src={streamkarlogo} type="video/mp4" className='w-100' alt='' />
                    </Col>
                </Row>
                <br />
                <br />
                <Row justify={'center'} align={'middle'} >
                    <Col xs={6} md={5} xl={5} xxl={5} className='menu-item'>
                        <a href='#ourmorkandmission'>about us.</a>
                    </Col>
                    <Col xs={6} md={5} xl={5} xxl={5} className='menu-item'>
                        <a href='#ourmorkandmission'>our mission.</a>
                    </Col>
                    <Col xs={6} md={5} xl={5} xxl={5} className='menu-item'>
                        <a href='#ourinitiative'>our initiative.</a>
                    </Col>
                    <Col xs={6} md={5} xl={5} xxl={5} className='menu-item'>
                        <a href='#contact'>contact.</a>
                    </Col>
                    <Col xs={0} md={24} xl={24} xxl={24}>
                    
                    <br/></Col>
                </Row>
                <br />
                <Row justify={'center'} align={'middle'} >
                    <Col xs={5} md={5} xl={5} xxl={5} className='menu-item'>
                        <a href='#aboutus'>#StreamKares.
                        <br />
                        <DownOutlined className='downarrow' /></a>
                    </Col>
                </Row>
            </div>
        </div>
        <div className="webpage-content">
            <div className='initiativebanner'>
                <img src={initiativebanner} type="video/mp4" className='w-100' alt='' />
            </div>
            <div className='aboutus' id="aboutus">
                <Row justify={'center'}>
                    <Col span={24} align={'middle'}>
                        <Title>about Us</Title>
                    </Col>
                    <Col span={20} align={'middle'}>
                        <Text strong>StreamKar is India’s top-live streaming app. We are dedicated to creating interesting, dynamic <br />and trendy entertainment for our live-streaming viewers. We are also dedicated to community<br /> and national welfare. This is what #StreamKares is all about.</Text>
                    </Col>
                </Row>
            </div>
            <div className="backgroundimage3 backgroundimage">
                <Row justify={'center'} className='ourworkmission'>
                    <Col xs={24} md={10} lg={10}>
                        <img src='' alt="" className="our-work-mission-p1 our-work-mission-images" />
                        <img src='' alt="" className="our-work-mission-p2 our-work-mission-images" />
                        <img src='' alt="" className="our-work-mission-p3 our-work-mission-images" />
                        <img src='' alt="" className="our-work-mission-p4 our-work-mission-images" />
                    </Col>
                    <Col xs={22} md={10} lg={10} align='middle'>
                        <Title level={2} className="w-color" id="ourmorkandmission">our work & mission</Title>
                        <Text className="w-color o-8" >StreamKar, Melot Technologies and #StreamKares regularly conducts charity events across Mumbai and Greater India on its own initiative and with partner NGOs. Our mission is to share joy and to help ensure everyone has a reason to smile, despite their life circumstances. </Text>
                    </Col>
                </Row>
            </div>
            <div className="our-initiative">
                <Row justify={'center'}>
                    <Col xs={24} md={24} align={'middle'}>
                        <Title id="ourinitiative">our initiative</Title>
                    </Col>
                    <Col xs={22} md={22} align={'middle'}>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} md={12} lg={8}>
                                <Card
                                    className="initiative-card"
                                    hoverable
                                    cover={<img alt="example" src={doginitiative} className="w-100" />}
                                >
                                    <Title level={2} className="b-color">Dog Collar Giveaway</Title>
                                    <Text  >In cooperation with the Mumbai-based Awaaz Foundation, StreamKar lead a campaign to place dog collars</Text>
                                    <br />
                                 <a href="https://www.aninews.in/news/business/indias-live-streaming-mobile-app-streamkar-launches-skcollarfordogs-mission-500-on-international-dog-day-to-protect-stray-dogs-from-road-accidents20210829164812/">  <button className=" b-color read-more-btn" >Read More {'>>'}</button></a>
                                </Card>
                            </Col>
                            <Col xs={24} md={12} lg={8}>
                                <Card
                                    className="initiative-card"
                                    hoverable
                                    cover={<img alt="example" src={foodinitiative} className="w-100" />}
                                >
                                    <Title level={2} className="b-color">Food giveaway</Title>
                                    <Text  >
                                        - During the height of the pandemic, StreamKar gave away food bundles to the residents of various slums in Mumbai in order to make sure no matter what the world brings, people have access to basic foods and comforts that
                                        - Slum children giveaway

                                    </Text>

                                </Card>
                            </Col>
                            <Col xs={24} md={12} lg={8}>
                                <Card
                                    className="initiative-card"
                                    hoverable
                                    cover={<img alt="example" src={maskinitiative} className="w-100" />}
                                >
                                    <Title level={2} className="b-color">#SKMaskOn Initiative</Title>
                                    <Text  >
                                        - During the pandemic, we reached out to villages in Jharkhand and Chhattisgarh to give away Covid-19 masks to ensure safety and proper education to local people.
                                        - Images in folder

                                    </Text>

                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <div className="gallery">
<Row>
    <Col xs={0} md={24}>
    <Row justify={'center'}>
                    <Col span={24} align={'middle'}>
                        <Row align={'middle'}>
                            <Col xs={3} md={2}>
                                <div className="lArrow">
                                    <img src={lArrow} alt="" className="w-100" onClick={() => galleryCarousel.current.prev()} />
                                </div>
                            </Col>
                            <Col xs={18} md={20} className="gallery-carousel">
                                <Title level={2} className='gallery-title'>gallery</Title>
                                        <Carousel dots={null} ref={galleryCarousel}>
                                            {[0, 3, 6, 9, 12, 15, 18, 21].map(index => {
                                                return (
                                                    <div>
                                                        <Row justify={'space-evenly'}>
                                                            <Col span={6}> <img src={gallery[index]} alt="" className="w-100" /></Col>
                                                            <Col span={6}> <img src={gallery[index + 1]} alt="" className="w-100" /></Col>
                                                            <Col span={6}> <img src={gallery[index + 2]} alt="" className="w-100" /></Col>
                                                        </Row>
                                                    </div>)
                                            })}
                                        </Carousel>                                  
                            </Col>
                            <Col xs={3} md={2}>
                                <div className="rArrow">
                                    <img src={rArrow} alt="" className="w-100" onClick={() => galleryCarousel.current.next()} />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
    </Col>
    <Col xs={24} md={0}>
    <Row justify={'center'}>
                    <Col span={24} align={'middle'}>
                        <Row align={'middle'}>
                            <Col xs={3} md={2}>
                                <div className="lArrow">
                                    <img src={lArrow} alt="" className="w-100" onClick={() => galleryCarousel.current.prev()} />
                                </div>
                            </Col>
                            <Col xs={18} md={20} className="gallery-carousel">
                                <Title level={2} className='gallery-title'>gallery</Title>
                                        <Carousel dots={null} ref={galleryCarousel}>
                                            {[0, 2,4,6,8,10,12,14,16,18,20].map(index => {
                                                return (
                                                    <div>
                                                        <Row justify={'space-evenly'}>
                                                            <Col span={8}> <img src={gallery[index]} alt="" className="w-100" /></Col>
                                                            <Col span={8}> <img src={gallery[index + 1]} alt="" className="w-100" /></Col>
                                                        </Row>
                                                    </div>)
                                            })}
                                        </Carousel>                                  
                            </Col>
                            <Col xs={3} md={2}>
                                <div className="rArrow">
                                    <img src={rArrow} alt="" className="w-100" onClick={() => galleryCarousel.current.next()} />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
    </Col>
</Row>

            </div>
            <div className="videos-collection">
                <Row align={'middle'} justify={'space-between'}>
                    <Col xs={2} md={2} align={'middle'}>
                        <div >
                            <LeftCircleFilled className="circle-arrow w-color" onClick={() => videoCarousel.current.prev()} />
                        </div>
                    </Col>
                    <Col xs={20} md={14} className="gallery-carousel" align={'middle'}>
                        <Row justify={'center'}>
                            <Col span={18}>
                                <Carousel dots={null} ref={videoCarousel}>
                                    <div className="video-bg">
                                        <video className='videoCarousel' controls>
                                            <source src={videos[0]} type="video/mp4" />
                                        </video>
                                    </div>
                                    <div className="video-bg">
                                        <video className='videoCarousel' controls>
                                            <source src={videos[1]} type="video/mp4" />
                                        </video>
                                    </div>
                                </Carousel>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={2} md={2} align={'middle'}>
                        <div >
                            <RightCircleFilled onClick={() => videoCarousel.current.next()} className="circle-arrow w-color" />
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="contact-details">
                <Row align={'middle'} justify={'center'} gutter={[16, 16]}>
                    <Col span={24} align={'middle'}><Title id="contact">contact.</Title></Col>
                    <Col xs={22} md={20} lg={11}>
                        <Card className="contact-card">
                            {/* <FacebookFilled /><InstagramFilled /> */}
                            <img alt='' src={facebook} className='media-icon' /> <img alt='' src={insta} className='media-icon' />
                            <br />
                            <Text>Copyrights © 2023 All Rights Reserved by Melot Technologies Pvt Ltd.</Text>
                        </Card>
                    </Col>
                    <Col xs={22} md={20} lg={11} >
                        <Card className="contact-card" align={'end'}>
                            <Text className=" b-color"><MailOutlined /> Mail: melot@streamkar.com</Text><br />
                            <Text> <HomeOutlined /> Address: Melot Technologies Private Limited,  Plot-542,   Grd-Floor Raj Mahal, Miya Mohd Chhotani Road, Mahim, Mumbai, Maharashtra- 400016</Text>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>

    </>)
}
export default CharityWebsite;