import './App.css';
import CharityWebsite from './Components/CharityWebsite';

function App() {
  return (
    <div className="App">
     <CharityWebsite/>
    </div>
  );
}

export default App;
